import axios from 'axios';
import { normalize } from 'normalizr';
import * as types from './types';
import store from '../store';
import schemas from './schemas';
import * as storage from '../persistence/storage';
import { API_ROOT } from '../constants';
import eventTracking from '../utils/event-tracking';
import api from '../api';

const locale =
  storage.get('locale') || store.getState().application.locale || 'en';

export const authenticate = ({rememberMe, ...authData}) => (dispatch) => {
  dispatch({ type: types.AUTHENTICATE_PENDING, payload: {} });
  axios
    .post(`${API_ROOT}/user/authenticate`, authData)
    .then(response => {
      dispatch({
        type: types.AUTHENTICATE_SUCCESS,
        payload: {
          ...response.data,
          rememberMe,
        }
      });
      eventTracking('home page', `Sign In`, 'successful sign in');
    })
    .catch(err => {
      dispatch({
        type: types.AUTHENTICATE_REJECTED,
        payload: err
      });
    });
};

export const authenticateImpersonation = authData => dispatch => {
  dispatch({ type: types.AUTHENTICATE_PENDING, payload: {} });
  axios
    .post(`${API_ROOT}/user/impersonationAuth`, authData)
    .then(response => {
      if (authData.isMemImpersonationAdmin) {
        response.data.user.roles.push('demo');
      }
      dispatch({
        type: types.AUTHENTICATE_SUCCESS,
        payload: response.data
      });
      window.location.replace('/dashboard');
    })
    .catch(err => {
      dispatch({
        type: types.AUTHENTICATE_REJECTED,
        payload: err
      });
    });
};

export const getAgentData = () => dispatch => {
  dispatch({ type: types.FETCH_AGENT_PENDING, payload: {} });
  const {
    token,
    currentUser: {
      person: { _id: id }
    }
  } = store.getState().session;
  if (!token) {
    dispatch({
      type: types.FETCH_AGENT_REJECTED,
      payload: { message: 'User is not authenticated' }
    });
  } else {
    axios.defaults.headers.common['X-Token'] = token;
    axios
      .get(`${API_ROOT}/agents/person/${id}`)
      .then(response => {
        dispatch({
          type: types.FETCH_AGENT_SUCCESS,
          payload: response.data.data.data
        });
      })
      .catch(err => {
        dispatch({
          type: types.AUTHENTICATE_REJECTED,
          payload: err
        });
      });
  }
};

export const getLifestyles = () => dispatch => {
  dispatch({ type: types.FETCH_LIFESTYLES_PENDING, payload: {} });
  const { token } = store.getState().session;
  if (!token) {
    dispatch({
      type: types.FETCH_LIFESTYLES_REJECTED,
      payload: { message: 'User is not authenticated' }
    });
  } else {
    axios.defaults.headers.common['X-Token'] = token;
    axios
      .get(`${API_ROOT}/tagCategories/lifestyles/get-all`)
      .then(response => {
        const normalizedTags = normalize(
          response.data.data.allTags,
          schemas.TAGS_LIST
        );
        const lifestyles = response.data.data.lifestyles.map(lifestyle => ({
          _id: lifestyle._id,
          displayName: lifestyle.name[locale],
          name: lifestyle.lifestyleName
        }));
        dispatch({
          type: types.FETCH_LIFESTYLES_SUCCESS,
          payload: {
            allTags: normalizedTags.entities.allTags,
            lifestyles,
            tags: response.data.data.tags
          }
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCH_LIFESTYLES_REJECTED, payload: err });
      });
  }
};

export const getBrokerageData = (
  page = 1,
  limit = 1200,
  showArchived = true
) => dispatch => {
  dispatch({ type: types.FETCH_BROKERAGES_PENDING, payload: {} });
  const { token } = store.getState().session;
  if (!token) {
    dispatch({
      type: types.FETCH_BROKERAGES_REJECTED,
      payload: { message: 'User is not authenticated' }
    });
  } else {
    axios.defaults.headers.common['X-Token'] = token;
    axios
      .post(`${API_ROOT}/brokerages/filter/`, {
        page,
        limit,
        showArchived
      })
      .then(response => {
        const brokerageData = response.data.data.data;
        const filteredData = brokerageData.filter(record => !record.deletedAt);
        dispatch({
          type: types.FETCH_BROKERAGES_SUCCESS,
          payload: {
            brokerages: filteredData,
            totalBrokerages: filteredData.length
          }
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCH_BROKERAGES_REJECTED, payload: err });
      });
  }
};

export const getPersonData = () => dispatch => {
  dispatch({ type: types.FETCH_PERSON_PENDING, payload: {} });
  const { token } = store.getState().session;
  if (!token) {
    dispatch({
      type: types.FETCH_PERSON_REJECTED,
      payload: { message: 'User is not authenticated' }
    });
  } else {
    axios.defaults.headers.common['X-Token'] = token;
    axios
      .get(`${API_ROOT}/user/me/`)
      .then(response => {
        dispatch({
          type: types.FETCH_PERSON_SUCCESS,
          payload: response.data.data
        });
      })
      .catch(err => {
        dispatch({ type: types.FETCH_PERSON_REJECTED, payload: err });
      });
  }
};

export const getSystemSettings = () => dispatch => {
  dispatch({ type: types.FETCH_SETTINGS_PENDING, payload: {} });
  axios
    .get(`${API_ROOT}/info/system-settings/`)
    .then(response => {
      dispatch({
        type: types.FETCH_SETTINGS_SUCCESS,
        payload: response.data.data
      });
    })
    .catch(err => {
      dispatch({ type: types.FETCH_SETTINGS_REJECTED, payload: err });
    });
};

export const getSearchSettings = () => dispatch => {
  dispatch({ type: types.FETCH_SEARCH_PENDING, payload: {} });
  axios
    .get(`${API_ROOT}/info/search-settings/`)
    .then(response => {
      dispatch({
        type: types.FETCH_SEARCH_SUCCESS,
        payload: response.data.data
      });
    })
    .catch(err => {
      dispatch({ type: types.FETCH_SEARCH_REJECTED, payload: err });
    });
};

export const getDashboardSettings = () => dispatch => {
  dispatch({ type: types.FETCH_DASHBOARD_PENDING, payload: {} });
  axios
    .get(`${API_ROOT}/info/dashboard-settings/`)
    .then(response => {
      dispatch({
        type: types.FETCH_DASHBOARD_SUCCESS,
        payload: {
          dashboardSettings: response.data.data.dashboardSettings,
          //dashboardRotation: 1, // SHOW 1st item always
          dashboardRotation: Math.ceil(
            Math.random() * response.data.data.dashboardSettings.data.length
          )
        }
      });
    })
    .catch(err => {
      dispatch({ type: types.FETCH_DASHBOARD_REJECTED, payload: err });
    });
};
export const setNextDashboard = () => dispatch => {
  const { dashboardRotation, dashboardSettings } = store.getState().session;
  let nextDashboard = dashboardRotation + 1;
  if (nextDashboard > dashboardSettings.length) nextDashboard = 1;
  dispatch({
    type: types.NEXT_DASHBOARD,
    payload: nextDashboard
  });
  return nextDashboard;
};
export const updateAgent = data => dispatch => {
  dispatch({ type: types.UPDATE_AGENT_PENDING, payload: {} });
  const {
    token,
    agentData: { _id: id }
  } = store.getState().session;
  if (!token) {
    dispatch({
      type: types.UPDATE_AGENT_REJECTED,
      payload: { message: 'User is not authenticated' }
    });
  } else {
    axios.defaults.headers.common['X-Token'] = token;
    axios
      .put(`${API_ROOT}/agents/${id}`, data)
      .then(response => {
        dispatch({
          type: types.UPDATE_AGENT_SUCCESS,
          payload: response.data.data.data
        });
      })
      .catch(err => {
        dispatch({ type: types.UPDATE_AGENT_REJECTED, payload: err });
      });
  }
};

export const updateBrokerage = (id, data) => dispatch => {
  dispatch({ type: types.UPDATE_BROKERAGE_PENDING, payload: {} });
  const { token } = store.getState().session;
  if (!token) {
    dispatch({
      type: types.UPDATE_BROKERAGE_REJECTED,
      payload: { message: 'User is not authenticated' }
    });
  } else {
    axios.defaults.headers.common['X-Token'] = token;
    axios
      .put(`${API_ROOT}/brokerages/${id}`, data)
      .then(response => {
        dispatch({
          type: types.UPDATE_BROKERAGE_SUCCESS,
          payload: response.data.data.data
        });
      })
      .catch(err => {
        dispatch({ type: types.UPDATE_BROKERAGE_REJECTED, payload: err });
      });
  }
};

export const updateSubscription = subscription => ({
  type: types.UPDATE_SUBSCRIPTION,
  payload: subscription
});

export const updateOurPerson = payload => ({
  type: types.UPDATE_PERSON_SUCCESS,
  payload: payload.data.data.data
});

export const rejectOurPerson = err => ({
  type: types.UPDATE_PERSON_REJECTED,
  payload: err
});

export const updatePerson = data => dispatch => {
  dispatch({ type: types.UPDATE_PERSON_PENDING, payload: {} });
  const {
    token,
    currentPerson: { _id: id }
  } = store.getState().session;
  if (!token) {
    dispatch({
      type: types.UPDATE_PERSON_REJECTED,
      payload: { message: 'User is not authenticated' }
    });
  } else {
    axios.defaults.headers.common['X-Token'] = token;
    axios
      .put(`${API_ROOT}/people/${id}`, data)
      .then(response => {
        dispatch({
          type: types.UPDATE_PERSON_SUCCESS,
          payload: response.data.data.data
        });
      })
      .catch(err => {
        dispatch({ type: types.UPDATE_PERSON_REJECTED, payload: err });
      });
  }
};

export const updateTags = section => dispatch => {
  dispatch({ type: types.UPDATE_TAGS_PENDING, payload: {} });
  const { token } = store.getState().session;
  if (!token) {
    dispatch({
      type: types.UPDATE_TAGS_REJECTED,
      payload: { message: 'User is not authenticated' }
    });
  } else {
    axios.defaults.headers.common['X-Token'] = token;
    axios
      .post(`${API_ROOT}/tagCategories/filter`, {
        filter: {
          appliesTo: section
        }
      })
      .then(response => {
        dispatch({
          type: types.UPDATE_TAGS_SUCCESS,
          payload: { category: section, tags: response.data.data.data }
        });
      })
      .catch(err => {
        dispatch({ type: types.UPDATE_TAGS_REJECTED, payload: err });
      });
  }
};

export const updateClientFilters = newFilters => ({
  type: types.UPDATE_CLIENT_FILTERS,
  payload: newFilters
});

export const updatePlace = place => ({
  type: types.UPDATE_PLACE,
  payload: place
});

export const retryAuthentication = () => ({
  type: types.AUTHENTICATE_RETRY,
  payload: {}
});

export function clearSession() {
  return { type: types.CLEAR_SESSION, payload: null };
}

export function completeOnboarding() {
  return { type: types.COMPLETE_ONBOARDING, payload: null };
}

export function acceptCookies() {
  return { type: types.ACCEPT_COOKIES, payload: true };
}

export function checkSubscription() {
  return { type: types.CHECK_SUBSCRIPTION, payload: null };
}

export function checkTrialSubscription() {
  return { type: types.CHECK_TRIAL_SUBSCRIPTION, payload: null };
}

export function endPendingSubscription() {
  return { type: types.END_PENDING_SUBSCRIPTION, payload: null };
}

export function renewSubscription() {
  return { type: types.REONBOARD, payload: null };
}

export function setPendingSubscription() {
  return { type: types.SET_PENDING_SUBSCRIPTION, payload: null };
}

export function expireSubscription() {
  return { type: types.SUBSCRIPTION_EXPIRED, payload: null };
}

export function setGroup(group) {
  return { type: types.SET_GROUP, payload: group };
}
export function clearPastDue() {
  return { type: types.CLEAR_PASTDUE_PAYMENT, payload: null };
}
export function handleReferralDialog(agentInfo) {
  return { type: types.OPEN_REFERRAL_DIALOG, payload: agentInfo };
}


export const getAmityToken = () => dispatch => {
  const {
    token,
  } = store.getState().session;
  axios.defaults.headers.common['X-Token'] = token;
  axios
    .get(`${API_ROOT}/chat/getUserToken`)
    .then(response => {
      dispatch({
        type: types.FETCH_AMITY_TOKEN,
        payload: response.data.data.data
      });
    })
    .catch(err => {
      dispatch({
        type: types.AUTHENTICATE_REJECTED,
        payload: err
      });
    });
};
