/* eslint-disable react/jsx-props-no-spreading */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash.isempty';
import isFunction from 'lodash.isfunction';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import classNames from 'classnames';

import classes from './App.module.scss';

import AppRoutes from './AppRoutes';
import Subscriptions from '../Subscriptions';

import AlertDialog from '../../components/Dialogs/AlertDialog';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Drawer from '../../components/Navigation/Drawer';
import LoadingIndicator from '../../components/LoadingIndicator';
import ErrorDialog from '../../components/Dialogs/ErrorDialog';

import getParameterByName from '../../utils/get-parameter-by-name';
import * as storage from '../../persistence/storage';

import api from '../../api';
import eventTracking from '../../utils/event-tracking';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { ReferralDialog } from '../../components/ReferralDialog';
import { c10CacheBuster } from '../../utils/cacheBusting';
const { body } = document;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listenForScroll: false,
      showExpiredDialog: false,
      showInactiveUserExpiredDialog: false

    };
    this.wrapperRef = createRef();
    this.contentRef = createRef();
    this._endScrollTimeout = 200;
    this._pageScrollTimeout = null;
  }

  static getDerivedStateFromProps(props) {
    return {
      shouldShowFooter: props.router.location.pathname !== "/agents-map"
    }
  }

  componentDidMount() {
    const { actions, session } = this.props;
    !session.currentPerson && actions.getPersonData();
    !session.brokerages && actions.getBrokerageData();
    !session.agentData && actions.getAgentData();
    !session.searchSettings && actions.getSearchSettings();
    if (!session.dashboardSettings) {
      actions.getDashboardSettings();
      actions.getSystemSettings();
    }
    if (
      !session.lifestyles ||
      isEmpty(session.allTags) ||
      isEmpty(session.tags)
    ) {
      actions.getLifestyles();
    }
    if (this.wrapperRef && this.wrapperRef.current) {
      this.wrapperRef.current.addEventListener('scroll', this._onScroll);
    }
    window.addEventListener('resize', this.checkForScrolling);
    this.checkForScrolling();
    const redirectPath = getParameterByName('redirectTo');
    if (redirectPath) {
      this.props.push(`/${redirectPath}`);
    }
    if (session.redirect) {
      this.props.push(session.redirect);
      session.redirect = null;
    }
    if (
      session.currentUser.isActive === false ||
      session.hasCancelledSubscription
    ) {
      this.showInactiveUserExpiredDialog();
    } else if (
      session.hasTrialExpired ||
      session.hasSubscriptionExpired
    ) {
      this.showExpiredDialog();
    } else {
      this.setState({
        showExpiredDialog: false,
        showInactiveUserExpiredDialog: false
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { actions, session } = this.props;
    const { pathname, search } = this.props.router.location;
    api.updateToken();
    const redirectPath = getParameterByName('redirectTo');
    this.checkForScrolling();
    const subStatus = `trial: ${session.hasTrialExpired}. subExpired: ${session.hasSubscriptionExpired}. onboarding: ${session.isOnboarding}. Path: ${pathname}. Redirect: ${redirectPath}`;
    window.localStorage.setItem('AppUpdateVars', subStatus);
    if (
      prevProps.session.hasTrialExpired !== session.hasTrialExpired ||
      prevProps.session.hasSubscriptionExpired !==
        session.hasSubscriptionExpired ||
      prevProps.session.hasCancelledSubscription !==
        session.hasCancelledSubscription ||
        prevProps.session.currentUser.isActive != session.currentUser.isActive
    ) {
      if (
        session.currentUser.isActive === false ||
        session.hasCancelledSubscription
      ) {
        this.showInactiveUserExpiredDialog();
      } else if (
        session.hasTrialExpired ||
        session.hasSubscriptionExpired
      ) {
        this.showExpiredDialog();
      } else {
        this.setState({
          showExpiredDialog: false,
          showInactiveUserExpiredDialog: false
        });
      }
    }
    if (
      prevProps.session.isOnboarding &&
      !this.props.session.isOnboarding &&
      !this.props.session?.subscription?.processedBy === 'Manual'
    ) {
      this.props.actions.getPersonData();
    }
    if (prevProps.router.location.search !== search && redirectPath) {
      this.props.push(`/${redirectPath}`);
    }
    if (prevProps.router.location.pathname !== pathname) {
      eventTracking(pathname, 'PageLoad', search);
      session.hasTrialSubscription && actions.checkTrialSubscription();
      if (
        this.wrapperRef &&
        this.wrapperRef.current &&
        isFunction(this.wrapperRef.current.scrollTo)
      ) {
        this.wrapperRef.current.scrollTo(0, 0);
      }
    }
    if (!this.state.listenForScroll) {
      this._checkForScrollSetup();
    }
    c10CacheBuster();
  }

  componentWillUnmount() {
    if (this.wrapperRef && this.wrapperRef.current) {
      this.wrapperRef.current.removeEventListener('scroll', this._onScroll);
    }
    window.removeEventListener('resize', this.checkForScrolling);
  }

  showExpiredDialog = () => {
    this.setState({
      showExpiredDialog: true,
      showInactiveUserExpiredDialog: false,
    });
  };

  showInactiveUserExpiredDialog = () => {
    this.setState({
      showInactiveUserExpiredDialog: true,
      showExpiredDialog: false
    });
  };
  // SCROLLING
  _checkForScrollSetup = () => {
    if (this.wrapperRef && this.wrapperRef.current) {
      this.setState(
        {
          listenForScroll: true
        },
        () => {
          this.wrapperRef.current.addEventListener('scroll', this._onScroll);
        }
      );
    }
  };

  _onScroll = () => {
    this.props.actions.onScroll(true);
    if (!window.document.body.classList.contains('scrolling')) {
      window.document.body.classList.add('scrolling');
      this.wrapperRef.current.removeEventListener('scroll', this._onScroll);
    }
    this._pageScrollTimeout = window.setTimeout(
      this._onScrollEnd,
      this._endScrollTimeout
    );
  };

  _onScrollEnd = () => {
    this.props.actions.onScroll(false);
    clearTimeout(this._pageScrollTimeout);
    body.classList.remove('scrolling');
    this.wrapperRef.current.addEventListener('scroll', this._onScroll);
  };

  checkForScrolling = () => {
    if (this.props.session.isOnboarding) {
      return null;
    }
    const wrapperDiv = this.wrapperRef.current;
    const contentDiv = this.contentRef.current;
    const wrapperTopPadding = 53;
    if (wrapperDiv && contentDiv) {
      const wrapperRect = wrapperDiv.getBoundingClientRect();
      const contentRect = contentDiv.getBoundingClientRect();
      if (wrapperTopPadding + contentRect.height > wrapperRect.height) {
        body.classList.add('show-scrollbar');
      } else {
        body.classList.remove('show-scrollbar');
      }
    }
    return null;
  };

  redirectToSubscriptions = () => {
    this.props.actions.renewSubscription();
    this.props.push('/');
  };

  handleLogOut = () => {
    this.setState(
      {
        showExpiredDialog: false,
        showInactiveUserExpiredDialog: false
      },
      () => {
        this.props.push('/logout');
      }
    );
  };

  renderBackOffice = () => {
    const { actions, application, session, theme, ...rest } = this.props;
    if (session.hasSuspendedSubscription) {
      return (
        <ErrorDialog
          handleClose={() => {
            storage.clear();
            if (session.acceptCookies) {
              storage.put('acceptCookies', session.acceptCookies);
            }
            actions.clearApplication();
            actions.clearSession();
          }}
          open
          message={
            <span>
              There is a problem with your account. Please{' '}
              <a href="mailto:concierge@realm-global.com">
                contact the concierge
              </a>{' '}
              for further assistance.
            </span>
          }
        />
      );
    }

    return (
      <>
        <SkeletonTheme
          id="skeletor"
          baseColor="var(--white)"
          highlightColor="var(--background)"
        >
          <div className={classes.header}>
            <Header
              agentData={session.agentData}
              avatar={session.avatar}
              currentPerson={session.currentPerson}
              isAdmin={session.isAdmin}
              isAgent={session.isAgent}
              isConcierge={session.isConcierge}
              isRealmAdmin={session.isRealmAdmin}
              isRealmExecutive={session.isRealmExecutive}
              isMobile={application.isMobile}
              isMobileOnly={application.isMobileOnly}
              isOnboarding={session.isOnboarding}
              isResizing={application.isResizing}
              location={this.props.router.location.pathname}
              subscription={session.subscription}
              myGroups={session.myGroups}
              selectedGroup={session.selectedGroup}
              actions={actions}
              push={this.props.push}
              session={session}
            />
          </div>
          <div className={classes.navigation}>
            <Drawer />
          </div>
          <div className={classes.content}>
            <div
              id="page-wrapper"
              className={application.isMobile ? 'mobile' : 'desktop'}
              ref={this.wrapperRef}
            >
              <div className={classNames("page-content", this.state.shouldShowFooter && "footer-shown")} ref={this.contentRef}>
                <AppRoutes
                  actions={actions}
                  application={application}
                  session={session}
                  {...rest}
                />
                <AlertDialog
                  message={`
                    <p>If you would like to continue using REALM, you will need to renew your membership. For assistance,
                    <a href="mailto:concierge@realm-global.com">
                      please contact the concierge
                    </a>
                    .
                  </p>`}
                  onClose={this.handleLogOut}
                  open={this.state.showExpiredDialog}
                  showCloseButton
                  title="Your REALM Membership has expired"
                  actionsMap={[
                    {
                      color: 'primary',
                      onClick: this.redirectToSubscriptions,
                      text: 'Renew'
                    }
                  ]}
                />
                <AlertDialog
                  message={`
                    <p>Please conctact with Member Services</p>
                    <a href="mailto:concierge@realm-global.com">
                      Click here to connect with concierge,
                    </a>
                    <p>or contact manually by email: concierge@realm-global.com</p>
                    <br></br>
                    `
                  }
                  onClose={this.handleLogOut}
                  open={this.state.showInactiveUserExpiredDialog}
                  showCloseButton
                  title="Your REALM Membership has been cancelled"
                />
                <ReferralDialog
                  agentId={session?.referralAgent?.agentId || null}
                  agentFullName={session?.referralAgent?.agentName || null}
                  handleReferralDialog={actions.handleReferralDialog}
                  fromAdmin={session.isAdmin}
                />
              </div>
              {this.state.shouldShowFooter && <Footer />}
            </div>
          </div>
        </SkeletonTheme>
      </>
    );
  };

  renderSubscriptions = () => (
    <Subscriptions
      actions={this.props.actions}
      application={this.props.application}
      router={this.props.router}
      session={this.props.session}
    />
  );

  render() {
    const { application, session } = this.props;
    const isSubscribing =
      session.isOnboarding ||
      session.hasPendingSubscription ||
      (session.isAgent && !session.hasSubscription);
    if (
      isEmpty(session.currentPerson) ||
      isEmpty(session.searchSettings) ||
      isEmpty(session.dashboardSettings)
    ) {
      return (
        <div className="realm--loading-container">
          <LoadingIndicator />
        </div>
      );
    }

    const { isRTL } = application;

    return (
      <div id="wrapper">
        <div className={classes.root} dir={isRTL ? 'rtl' : 'ltr'}>
          {isSubscribing || session.hasPastDuePayment
            ? this.renderSubscriptions()
            : this.renderBackOffice()
          }
        </div>
      </div>
    );
  }
}

App.propTypes = {
  actions: PropTypes.object,
  application: PropTypes.object,
  classes: PropTypes.object,
  router: PropTypes.object,
  session: PropTypes.object
};

export default connect(null, { push })(App);
